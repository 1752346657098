@import '../components/variables';

.login-form-overlay {
  align-items: flex-start;
  animation: fadeIn 300ms;
  background: var(--background-soft-highlight-color);
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: #{ $spacing-unit * 0.5 }rem;
  position: absolute;
  top: 0;
  width: 100%;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

